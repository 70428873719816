(function() {

    var html5Types = {
        'email': 'email',
        'url': 'url',
        'date': 'date',
        'time': 'time',
        'week': 'week',
        'month': 'month',
        'number': 'number',
        'color': 'color',
        'range': 'number'
    };

    var html5Attributes = ['min', 'max', 'accept', 'pattern', 'minlength', 'maxlength'];

    dmx.bootstrap3forms = false;
    dmx.bootstrap4forms = false;
    dmx.bootstrap5forms = false;

    document.addEventListener("DOMContentLoaded", function(event) {
        dmx.bootstrap3forms = !!document.querySelector('script[src$="dmxBootstrap3Forms.js"]');
        dmx.bootstrap4forms = !!dmx.array(document.querySelectorAll('link[href]')).filter(function(node) { return /bootstrap[\/@]4(\.\d+)?(\.\d+)?(-beta\.?\d+)?\/(dist\/)?\w+\/bootstrap(\.rtl)?(\.min)?\.css/.test(node.getAttribute('href')); }).length;
        dmx.bootstrap5forms = !!dmx.array(document.querySelectorAll('link[href]')).filter(function(node) { return /bootstrap[\/@]5(\.\d+)?(\.\d+)?(-beta\.?\d+)?\/(dist\/)?\w+\/bootstrap(\.rtl)?(\.min)?\.css/.test(node.getAttribute('href')); }).length;
    });

    dmx.rules = {};

    dmx.validate = function(node) {
        if (node.tagName == 'FORM') {
            var valid = true;

            for (var i = 0; i < node.elements.length; i++) {
                if (!dmx.validate(node.elements[i])) {
                    valid = false;
                }
            }

            if (dmx.bootstrap4forms || dmx.bootstrap5forms) {
              node.classList.add('was-validated');
            }

            return valid;
        }

        if (node.type == 'checkbox' || node.type == 'radio') {
            var firstCheckbox = null; //document.querySelector('input[name="' + node.name + '"]');
            if (node.form) {
                firstCheckbox = dmx.array(node.form.elements).find(function(elm) {
                    return elm.name == node.name;
                });
            }
            if (firstCheckbox && node != firstCheckbox) {
                return dmx.validate(firstCheckbox);
            }
        }

        if (!node.dirty) {
            node.dirty = true;
            if (dmx.bootstrap3forms) {
                var setCustomValidity = node.setCustomValidity;
                node.setCustomValidity = function(message) {
                    setCustomValidity.call(node, message);
                    dmx.validate.setBootstrapMessage(node, message);
                };
            }
        }

        dmx.requestUpdate();

        if (!node.willValidate) {
            node.setCustomValidity('');
            return true;
        }

        // Google reCAPTCHA v2
        if (node.getAttribute('name') == 'g-recaptcha-response' && !validity(node, 'required')) {
            return false;
        }

        if (node.hasAttribute('required') && !validity(node, 'required')) {
            return false;
        }

        if (node.value && node.value.length) {
            for (var type in html5Types) {
                if (node.type == type && !validity(node, html5Types[type])) {
                    return false;
                }
            }

            for (var i in html5Attributes) {
                var attr = html5Attributes[i];
                if (node.hasAttribute(attr) && !validity(node, attr, node.getAttribute(attr))) {
                    return false;
                }
            }

            for (var i = 0; i < node.attributes.length; i++) {
                var attribute = node.attributes[i];

                if (/^data-rule-/.test(attribute.name)) {
                    var rule = attribute.name.substr(10).toLowerCase();

                    if (!validity(node, rule, attribute.value)) {
                        return false;
                    }
                }
            }
        }

        node.setCustomValidity('');

        return true;
    };

    dmx.validateReset = function(node) {
        if (node.tagName == 'FORM') {
            for (var i = 0; i < node.elements.length; i++) {
                dmx.validateReset(node[i]);
            }

            if (dmx.bootstrap4forms || dmx.bootstrap5forms) {
              node.classList.remove('was-validated');
            }

            return;
        }

        node.dirty = false;

        if (dmx.bootstrap5forms) {
            dmx.validate.setBootstrap5Message(node, '');
            node.classList.remove('is-valid', 'is-invalid');
        } else if (dmx.bootstrap4forms) {
            dmx.validate.setBootstrap4Message(node, '');
            node.classList.remove('is-valid', 'is-invalid');
        } else if (dmx.bootstrap3forms) {
            dmx.validate.setBootstrapMessage(node, '');
            node.closest('.form-group').remove('has-success', 'has-success', 'has-feedback');
        } else {
            dmx.validate.setErrorMessage(node, '');
            node.classList.remove('dmxValidator-valid', 'dmxValidator-invalid');
        }
    }

    function validity(node, rule, param) {
        var valid = true;

        if (dmx.rules[rule]) {
            valid = dmx.rules[rule].validity(node, param);
            if (!valid) setMessage(node, rule, param);
        } else {
            console.warn('Validation rule ' + rule + ' not found!');
        }

        if (valid) {
            if (dmx.bootstrap5forms) {
                dmx.validate.setBootstrap5Message(node, '');
            } else if (dmx.bootstrap4forms) {
                dmx.validate.setBootstrap4Message(node, '');
            } else if (dmx.bootstrap3forms) {
                dmx.validate.setBootstrapMessage(node, '');
            } else {
                dmx.validate.setErrorMessage(node, '');
            }
        }

        return valid;
    }

    function setMessage(node, rule, param) {
        var message = node.getAttribute('data-msg-' + rule) || dmx.rules[rule].message;
        if (Array.isArray(param)) {
            message = message.replace(/\{(\d)\}/g, function(match, i) {
                return param[i];
            });
        } else {
            message = message.replace(/\{0\}/g, param);
        }

        node.setCustomValidity(message);

        if (dmx.bootstrap5forms) {
            dmx.validate.setBootstrap5Message(node, message);
        } else if (dmx.bootstrap4forms) {
            dmx.validate.setBootstrap4Message(node, message);
        } else if (dmx.bootstrap3forms) {
            dmx.validate.setBootstrapMessage(node, message);
        } else {
            dmx.validate.setErrorMessage(node, message);
        }
    }

    dmx.validate.setErrorMessage = function(node, message) {
        if (node.hasAttribute('data-msg-custom') || !(node.getAttribute('name') || node.getAttribute('id'))) {
            return;
        }

        var type = node.type.toLowerCase();
        var id = 'dmxValidatorError' + (node.form ? node.form.getAttribute('id') : '') + (node.getAttribute('name') || node.getAttribute('id'));
        var err = document.getElementById(id);

        if (!err) {
            err = document.createElement('span');
            err.id = id;
            err.className = 'dmxValidator-error';

            if (type == 'checkbox' || type == 'radio') {
                var group = node.closest('.checkbox-group, .radio-group');

                if (group) {
                    group.insertAdjacentElement('afterend', err);
                } else {
                    node.insertAdjacentElement('afterend', err);
                }
            } else {
                node.insertAdjacentElement('afterend', err);
            }
        }

        err.textContent = message;

        if (message) {
            node.classList.remove('dmxValidator-valid');
            node.classList.add('dmxValidator-invalid');
        } else {
            node.classList.remove('dmxValidator-invalid');
            node.classList.add('dmxValidator-valid');
        }
    }

    dmx.validate.setBootstrapMessage = function(node, message) {
        if (node.hasAttribute('data-msg-custom') || !(node.getAttribute('name') || node.getAttribute('id'))) {
            return;
        }

        var type = node.type.toLowerCase();
        var id = 'dmxValidatorError' + (node.form ? node.form.getAttribute('id') : '') + (node.getAttribute('name') || node.getAttribute('id'));
        var err = document.getElementById(id);

        if (!err) {
            err = document.createElement('span');
            err.id = id;
            err.className = 'help-block';

            if (type == 'checkbox' || type == 'radio') {
                var group = node.closest('.checkbox-group, .radio-group');

                if (group) {
                    group.insertAdjacentElement('beforeend', err);
                } else {
                    var elm = node.closest('.checkbox, .radio');
                    if (elm) {
                        elm.insertAdjacentElement('afterend', err);
                    }
                }
            } else if (node.classList.contains('filestyle')) {
                node.nextSibling.insertAdjacentElement('afterend', err);
            } else if (node.parentElement.classList.contains('input-group')) {
                node.parentElement.insertAdjacentElement('afterend', err);
            } else {
                node.insertAdjacentElement('afterend', err);
            }
        }

        var formGroup = node.closest('.form-group');
        if (type != 'hidden' && node.name != 'g-recaptcha-response' && formGroup) {
            var feedback = formGroup.querySelector('.form-control-feedback');

            if (type != 'checkbox' && type != 'radio') {
                formGroup.classList.add('has-feedback');

                if (!feedback) {
                    feedback = document.createElement('span');
                    feedback.className = 'glyphicon form-control-feedback';
                    var elm = formGroup.querySelector('.input-group, .checkbox-group, input, select, textarea, button');
                    elm.insertAdjacentElement('afterend', feedback);
                }
            }

            if (message) {
                formGroup.classList.remove('has-success');
                formGroup.classList.add('has-error');
                if (feedback) {
                    feedback.classList.remove('glyphicon-ok');
                    feedback.classList.add('glyphicon-remove');
                }
            } else {
                formGroup.classList.remove('has-error');
                if (feedback) feedback.classList.remove('glyphicon-remove');
                if (!(node.form && node.form.hasAttribute('data-novalid'))) {
                    formGroup.classList.add('has-success');
                    if (feedback) feedback.classList.add('glyphicon-ok');
                }
            }
        }

        err.textContent = message;
    }

    dmx.validate.setBootstrap4Message = function(node, message) {
        if (node.hasAttribute('data-msg-custom') || !(node.getAttribute('name') || node.getAttribute('id'))) {
            return;
        }

        var type = node.type.toLowerCase();
        var id = 'dmxValidatorError' + (node.form ? node.form.getAttribute('id') : '') + (node.getAttribute('name') || node.getAttribute('id'));
        var err = document.getElementById(id);

        if (!err) {
            err = document.createElement('div');
            err.id = id;
            err.className = 'invalid-feedback';
            node.parentElement.appendChild(err);
        }

        if (node.hasAttribute('medium-editor-textarea-id')) {
            node = document.getElementById(node.getAttribute('medium-editor-textarea-id'));
        }

        if (type != 'hidden' && node.name != 'g-recaptcha-response') {
          if (message) {
            node.classList.remove('is-valid');
            node.classList.add('is-invalid');
          } else {
            node.classList.remove('is-invalid');
            node.classList.add('is-valid');
          }
        }

        err.textContent = message;
    }

    dmx.validate.setBootstrap5Message = function(node, message) {
        if (node.hasAttribute('data-msg-custom') || !(node.getAttribute('name') || node.getAttribute('id'))) {
            return;
        }

        var type = node.type.toLowerCase();
        var id = 'dmxValidatorError' + (node.form ? node.form.getAttribute('id') : '') + (node.getAttribute('name') || node.getAttribute('id'));
        var err = document.getElementById(id);

        if (!err) {
            err = document.createElement('div');
            err.id = id;
            err.className = 'invalid-feedback';
            node.parentElement.appendChild(err);
        }

        if (node.hasAttribute('medium-editor-textarea-id')) {
            node = document.getElementById(node.getAttribute('medium-editor-textarea-id'));
        }

        if (type != 'hidden' && node.name != 'g-recaptcha-response') {
          if (message) {
            node.classList.remove('is-valid');
            node.classList.add('is-invalid');
          } else {
            node.classList.remove('is-invalid');
            node.classList.add('is-valid');
          }
        }

        err.textContent = message;
    }

})();
